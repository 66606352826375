import React, { Component } from "react";
import Select from "react-select";
// import "react-select/dist/react-select.css";
import ReactTooltip from "react-tooltip";

export default class TailoringDemo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showPreview: false,
			previewURL:
				"https://api.staging.tuzagdesign.com/tailor/message/5/web?returnAsHTML=true&apiKey=nRvtIpRUNsxAuTUQ3w8DnRvtIpRUNsxAuTUQ3w8D"
		};
	}

	tailorData = (key, mid, raw = false) => {
		try {
			if (this.props.tailorData) {
				if (raw) {
					return this.props.tailorData[mid][key];
				}
				return (
					<span
						dangerouslySetInnerHTML={{
							__html: this.props.tailorData[mid][key]
						}}
					/>
				);
			} else {
				return <span />;
			}
		} catch (e) {
			//do nothing
		}
	};

	componentDidMount = async () => {
		this.setState({ tailorData: await tailor(1233), cheesetownSource: "" });

		const attributes = await tailorUsedAttributes();
		this.setState({ attributes: attributes.attributes });
	};

	tailorData(key, raw = false) {
		if (this.state.tailorData) {
			if (raw) {
				return this.state.tailorData[key];
			}
			return (
				<span
					dangerouslySetInnerHTML={{ __html: this.state.tailorData[key] }}
				/>
			);
		} else {
			return <span />;
		}
	}

	tailorAd = () => {
		let previewURL;
		if (process.env.GATSBY_REACT_APP_ENV === "staging") {
			previewURL =
				"https://api.staging.tuzagdesign.com/tailor/message/5/web?returnAsHTML=true&apiKey=nRvtIpRUNsxAuTUQ3w8DnRvtIpRUNsxAuTUQ3w8D";
		} else {
			previewURL =
				process.env.NODE_ENV === "development"
					? "http://localhost:3000/tailor/message/5/web?returnAsHTML=true&apiKey=nRvtIpRUNsxAuTUQ3w8DnRvtIpRUNsxAuTUQ3w8D"
					: "https://api.v2.tuzagdesign.com/tailor/message/5/web?returnAsHTML=true&apiKey=nRvtIpRUNsxAuTUQ3w8DnRvtIpRUNsxAuTUQ3w8D";
		}

		if ("famStatus" in this.state && this.state.famStatus) {
			previewURL += "&famStatus=" + this.state.famStatus.value;
		}

		if ("gender" in this.state && this.state.gender) {
			previewURL += "&gender=" + this.state.gender.value;
		}

		if ("cheeseAmt" in this.state && this.state.cheeseAmt) {
			previewURL += "&cheeseAmt=" + this.state.cheeseAmt.value;
		}

		if ("state" in this.state && this.state.state) {
			previewURL += "&state=" + this.state.state.value;
		}

		if ("favCheese" in this.state && this.state.favCheese) {
			previewURL += "&favCheese=" + this.state.favCheese.value;
		}

		if ("favSide" in this.state && this.state.favSide) {
			previewURL += "&favSide=" + this.state.favSide.value;
		}

		if ("hobbies" in this.state && this.state.hobbies) {
			previewURL += "&hobbies=" + this.state.hobbies.value;
		}

		this.setState({
			previewURL,
			showPreview: false
		});
	};

	handleChange(formField, val) {
		this.setState({ [formField]: val });
	}

	generateOptions(attribute) {
		return attribute.options.map(option => {
			return { value: option.sourceValue, label: option.name };
		});
	}

	render() {
		return (
			<div className="tailoringDemo">
				<div className="container">
					<div
						style={{
							border: "#D1383F 3px solid",
							borderRadius: 5,
							boxShadow: "3px 3px 12px #000000",
							padding: 15,
							marginBottom: 15
						}}
					>
						<div className="row">
							<div className="col text-center">
								<h3 style={{ color: "#D1383F" }}>
									{this.tailorData("headline", 4248)}
								</h3>
							</div>
						</div>
						{this.state.attributes ? (
							<div className="row">
								<div
									className="col-md d-md-none d-lg-none d-xl-none"
									style={{ alignSelf: "center" }}
								>
									<p>{this.tailorData("introduction", 4248)}</p>
								</div>
								<div className="col-md" style={{ alignSelf: "center" }}>
									<form
										onSubmit={event => {
											event.preventDefault();
										}}
									>
										{this.state.attributes.map(attribute => {
											if (
												attribute.type !== "Passthrough" &&
												attribute.name !== "First Name"
											) {
												return (
													<div
														style={{ marginTop: 15, marginBottom: 15 }}
														key={attribute.id}
													>
														<Select
															name={attribute.sourceVariable}
															value={this.state[attribute.sourceVariable]}
															options={this.generateOptions(attribute)}
															onChange={this.handleChange.bind(
																this,
																attribute.sourceVariable
															)}
															placeholder={attribute.name}
														/>
													</div>
												);
											} else {
												return null;
											}
										})}
										<div className="text-center">
											<button
												className="btn btn-default"
												style={{
													borderColor: "#D1383F",
													paddingTop: 10,
													paddingBottom: 10,
													paddingLeft: 50,
													paddingRight: 50,
													width: "40%",
													minWidth: 200
												}}
												onClick={this.tailorAd}
											>
												Generate
											</button>
										</div>
									</form>
								</div>
								<div className="col-md">
									<div className="text-center">
										<iframe
											title="Cheesetown Preview"
											frameBorder={0}
											style={{
												width: 180,
												height: 620,
												padding: "none",
												margin: "none",
												overflow: "hidden",
												visibility: this.state.showPreview
													? "visible"
													: "hidden"
											}}
											onLoad={() => {
												this.setState({ showPreview: true });
											}}
											src={this.state.previewURL}
											// srcDoc={this.state.cheesetownSource}
										/>
										<div
											style={{
												position: "absolute",
												height: 15,
												width: 180,
												top: 0,
												left: 0,
												right: 0,
												marginLeft: "auto",
												marginRight: "auto"
											}}
											data-tip={`<div>Background:</div>
											<div>
											family-status = 
											${this.state.famStatus ? this.state.famStatus.label : "unknown"}
											</div>`}
											data-html={true}
										/>
										<div
											style={{
												position: "absolute",
												height: 85,
												width: 180,
												top: 15,
												left: 0,
												right: 0,
												marginLeft: "auto",
												marginRight: "auto"
											}}
											data-tip={`<div>Call To Action:</div>
											<div>
											family-status = 
											${this.state.famStatus ? this.state.famStatus.label : "unknown"}
											</div>
											<div>
											gender = 
											${this.state.gender ? this.state.gender.label : "unknown"}
											</div>`}
											data-html={true}
										/>
										<div
											style={{
												position: "absolute",
												height: 100,
												width: 180,
												top: 85,
												left: 0,
												right: 0,
												marginLeft: "auto",
												marginRight: "auto"
											}}
											data-tip={`<div>Hobby:</div>
											<div>
											hobbies = 
											${this.state.hobbies ? this.state.hobbies.label : "unknown"}
											</div>`}
											data-html={true}
										/>
										<div
											style={{
												position: "absolute",
												height: 168,
												width: 180,
												top: 170,
												left: 0,
												right: 0,
												marginLeft: "auto",
												marginRight: "auto"
											}}
											data-tip={`<div>Body Copy:</div>
											<div>
											family-status = 
											${this.state.famStatus ? this.state.famStatus.label : "unknown"}
											</div>
											<div>
											fav-cheese = 
											${this.state.favCheese ? this.state.favCheese.label : "unknown"}
											</div>
											<div>
											fav-side = 
											${this.state.favSide ? this.state.favSide.label : "unknown"}
											</div>
											<div>
											hobbies = 
											${this.state.hobbies ? this.state.hobbies.label : "unknown"}
											</div>`}
											data-html={true}
										/>
										<div
											style={{
												position: "absolute",
												height: 40,
												width: 180,
												top: 338,
												left: 0,
												right: 0,
												marginLeft: "auto",
												marginRight: "auto"
											}}
											data-tip={`<div>State:</div>
											<div>
											state = 
											${this.state.state ? this.state.state.label : "unknown"}
											</div>`}
											data-html={true}
										/>
										<div
											style={{
												position: "absolute",
												height: 93,
												width: 180,
												top: 378,
												left: 0,
												right: 0,
												marginLeft: "auto",
												marginRight: "auto"
											}}
											data-tip={`<div>State:</div>
											<div>
											state = 
											${this.state.state ? this.state.state.label : "unknown"}
											</div>
											<div>
											fav-cheese = 
											${this.state.favCheese ? this.state.favCheese.label : "unknown"}
											</div>`}
											data-html={true}
										/>
										<div
											style={{
												position: "absolute",
												height: 40,
												width: 180,
												top: 471,
												left: 0,
												right: 0,
												marginLeft: "auto",
												marginRight: "auto"
											}}
											data-tip={`<div>Bottom tag:</div>
											<div>
												untailored
											</div>
											`}
											data-html={true}
										/>
										<div
											style={{
												position: "absolute",
												height: 100,
												width: 180,
												top: 506,
												left: 0,
												right: 0,
												marginLeft: "auto",
												marginRight: "auto"
											}}
											data-tip={`<div>Headline:</div>
											<div>
											cheese-consumption = 
											${this.state.cheeseAmt ? this.state.cheeseAmt.label : "unknown"}
											</div>`}
											data-html={true}
										/>
										<h2
											style={{
												top: -500,
												position: "relative",
												zIndex: -3,
												left: -10,
												marginBottom: -180
											}}
										>
											<i
												className="fa fa-circle-o-notch fa-spin"
												aria-hidden="true"
												style={{
													fontSize: 72,
													marginTop: 30,
													marginBottom: 15
												}}
											/>
											<br />
											Loading...
										</h2>
										<ReactTooltip />
									</div>
								</div>
								<div
									className="col-md d-none d-md-block d-lg-block d-xl-block"
									style={{ alignSelf: "center" }}
								>
									<p>{this.tailorData("introduction", 4248)}</p>
								</div>
							</div>
						) : (
							<h1 style={{ marginBottom: 50 }}>
								<i
									className="fa fa-circle-o-notch fa-spin"
									aria-hidden="true"
									style={{ fontSize: 72, marginTop: 30, marginBottom: 15 }}
								/>
								<br />
								Loading...
							</h1>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const tailor = async (messageID, data = {}) => {
	let apiURL;

	if (process.env.REACT_APP_ENV === "staging") {
		apiURL = "https://api.staging.tuzagdesign.com/tailor/message";
	} else {
		apiURL =
			process.env.NODE_ENV === "development"
				? "http://localhost:3000/tailor/message"
				: "https://api.v2.tuzagdesign.com/tailor/message";
	}

	data.apiKey = "nRvtIpRUNsxAuTUQ3w8DnRvtIpRUNsxAuTUQ3w8D";
	data.returnElementNames = true;

	const unParsedData = await fetch(`${apiURL}/${messageID}`, {
		method: "POST",
		credentials: "include",
		mode: "cors",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(data)
	});

	return await unParsedData.json();
};

const tailorUsedAttributes = async () => {
	const messageID = 5;
	let apiURL;

	if (process.env.REACT_APP_ENV === "staging") {
		apiURL = "https://api.staging.tuzagdesign.com/tailor/message";
	} else {
		apiURL =
			process.env.NODE_ENV === "development"
				? "http://localhost:3000/tailor/message"
				: "https://api.v2.tuzagdesign.com/tailor/message";
	}

	const unParsedData = await fetch(
		`${apiURL}/${messageID}/usedAttributes?apiKey=nRvtIpRUNsxAuTUQ3w8DnRvtIpRUNsxAuTUQ3w8D&returnElementsNames=true`,
		{
			method: "GET",
			credentials: "include",
			mode: "cors",
			headers: {
				"Content-Type": "application/json"
			}
		}
	);

	return await unParsedData.json();
};
