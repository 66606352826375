import React, { Component } from "react";
import { withPrefix } from "gatsby";

import { Helmet } from "react-helmet";
import debounce from "lodash.debounce";
import logoBlackWithBubble from "../images/tuzag_black_with_message_bubble.svg";
// import logoWhiteWithBubble from "../images/tuzag_white_with_message_bubble.svg";
// import tuzagTCS from "../images/tuzagTCS.svg";
import tuzagTCS from "../images/tuzagTCS.svg";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Intercom from "react-intercom";
import ReactGA from "react-ga";
import ReCAPTCHA from "react-google-recaptcha";
import { Element } from "react-scroll";
import tuzagDesignLogo from "../images/tuzagDesign.svg";
import ContentZoom from "react-content-zoom";
import Modal from "react-modal";
import TailoringDemo from "../components/Cheesetown";

const tailor = async (messageID, data = {}) => {
	let apiURL;

	if (process.env.GATSBY_REACT_APP_ENV === "staging") {
		apiURL = "https://api.staging.tuzagdesign.com/tailor/message";
	} else {
		apiURL =
			process.env.NODE_ENV === "development"
				? "http://localhost:3000/tailor/message"
				: "https://api.v2.tuzagdesign.com/tailor/message";
	}

	data.apiKey = "nRvtIpRUNsxAuTUQ3w8DnRvtIpRUNsxAuTUQ3w8D";
	data.returnElementSlugs = true;

	const unParsedData = await fetch(`${apiURL}/${messageID}`, {
		method: "POST",
		credentials: "include",
		mode: "cors",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(data)
	});

	return await unParsedData.json();
};

export default class Home extends Component {
	state = { scrollTop: 0 };

	componentDidMount = async () => {
		ReactGA.initialize("UA-67845484-12");
		ReactGA.set({
			page: window.location.pathname + window.location.search
		});
		ReactGA.pageview(window.location.pathname + window.location.search);

		window.addEventListener("scroll", this.setScrollDataInState);

		this.setState(
			{
				tailorData: await tailor("4243,4244,4245,4246,4247,4248,4249,4250,4234")
			},
			() => {
				console.log(this.state.tailorData);
			}
		);

		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	};

	resize() {
		this.setState({ windowWidth: window.innerWidth });
	}

	tailorData(key, mid, raw = false) {
		try {
			if (this.state.tailorData) {
				if (raw) {
					return this.state.tailorData[mid][key];
				}
				return (
					<span
						dangerouslySetInnerHTML={{
							__html: this.state.tailorData[mid][key]
						}}
					/>
				);
			} else {
				return <span />;
			}
		} catch (e) {
			//do nothing
		}
	}

	setScrollDataInState = debounce(() => {
		this.setState({
			scrollTop: document.body.scrollTop || document.documentElement.scrollTop
		});
	}, 50);

	onChange = value => {
		this.setState({ recaptcha: value });
	};

	render() {
		return (
			<div>
				<Helmet>
					{/*<script src={withPrefix("static/theme-js/jquery-1.11.3.min.js")} />*/}
					<script src={withPrefix("static/theme-js/bootstrap.js")} />
					<script src={withPrefix("static/theme-js/owl.carousel.min.js")} />
					<script src={withPrefix("static/theme-js/jquery.scrollTo-min.js")} />
					<script
						src={withPrefix("static/theme-js/jquery.magnific-popup.min.js")}
					/>
					<script src={withPrefix("static/theme-js/jquery.nav.js")} />
					<script src={withPrefix("static/theme-js/wow.js")} />
					<script src={withPrefix("static/theme-js/jquery.vegas.js")} />
					<script src={withPrefix("static/theme-js/plugins.js")} />
					<script src={withPrefix("static/theme-js/custom.js")} />
					<link
						href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
						rel="stylesheet"
						integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
						crossOrigin="anonymous"
					/>
					<link
						href="https://fonts.googleapis.com/css?family=Biryani:400,700|Cabin:700&display=swap"
						rel="stylesheet"
					/>
				</Helmet>
				{!this.state.tailorData ? (
					<div className="container text-center">
						<img
							src={logoBlackWithBubble}
							alt="tuzag logo"
							style={{ marginTop: 40 }}
						/>
						<h1>
							<i
								className="fa fa-circle-o-notch fa-spin"
								aria-hidden="true"
								style={{ fontSize: 72, marginTop: 50 }}
							/>
						</h1>
					</div>
				) : (
					<div>
						<Navbar
							tailorData={this.state.tailorData}
							scrollTop={this.state.scrollTop}
						/>
						<Element name="home" />
						<section
							className="home-section"
							id="about"
							style={{ paddingTop: 140 }}
						>
							<div className="home-section-overlay" />

							<div className="container">
								<div className="row">
									<div className="col-md-6">
										<img
											src={tuzagTCS}
											style={{ width: "100%", maxWidth: 600 }}
											alt="tuzag TCS"
										/>
									</div>
									<div
										className="col-md-8 col-sm-offset-4"
										style={{ marginTop: 40 }}
									>
										<h4 style={{ lineHeight: 1.4, fontWeight: 400 }}>
											{this.tailorData("introduction", 4244)}
										</h4>
									</div>
								</div>
							</div>
						</section>
						<Element name="tuzagDesign" />
						<section className="section-white">
							<div className="container">
								<div className="row">
									<div className="col-md-6 col-md-offset-3  text-center">
										<img
											alt="tuzag design logo"
											src={tuzagDesignLogo}
											style={{ width: "100%", maxWidth: 350 }}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12 d-none d-sm-block">
										<p className="section-subtitle" style={{ columnCount: 2 }}>
											{this.tailorData("introduction", 4245)}
										</p>
									</div>
									<div className="col-sm-12 d-block d-sm-none">
										<p className="section-subtitle">
											{this.tailorData("introduction", 4245)}
										</p>
									</div>
								</div>
								<div className="row ">
									<div className="col-md-4">
										<h3 className="section-title" style={{ color: "#D93994" }}>
											{this.tailorData("subhead", 4245)}
										</h3>
										<p>{this.tailorData("body", 4245)}</p>
									</div>

									<div className="col-md-8" style={{ alignSelf: "center" }}>
										<img
											src={require("../images/tcsScreenshot.png")}
											className="wow fadeInUp"
											style={{ width: "100%" }}
											alt="screenshots of tuzag Design"
										/>
									</div>
								</div>
							</div>
						</section>
						<Element name="tuzagTailor" />
						<section
							className="section-white"
							style={{ backgroundColor: "#F0F0F0" }}
						>
							<div className="container">
								<div className="row">
									<div className="col-md-6 col-md-offset-3  text-center">
										<img
											alt="tuzag tailor logo"
											src={require("../images/tuzagTailor.svg")}
											style={{ width: "100%", maxWidth: 350 }}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12 d-none d-sm-block">
										<p className="section-subtitle" style={{ columnCount: 2 }}>
											{this.tailorData(
												this.state.windowWidth < 576
													? "introductionMobile"
													: "introductionDesktop",
												4246
											)}
										</p>
									</div>
									<div className="col-sm-12 d-block d-sm-none">
										<p className="section-subtitle">
											{this.tailorData(
												this.state.windowWidth < 576
													? "introductionMobile"
													: "introductionDesktop",
												4246
											)}
										</p>
									</div>
								</div>
								<div className="row">
									<div
										className="col"
										onClick={() => {
											this.setState({ imageZoomModalOpen: true });
										}}
										style={{
											cursor:
												this.state.windowWidth < 576 ? "inherit" : "cursor"
										}}
									>
										<img
											alt="flow diagram"
											src={
												this.state.windowWidth < 576
													? require("../images/diagram.png")
													: "/static/img/svg.svg"
											}
											style={{ width: "100%" }}
										/>
									</div>
								</div>
								<div className="col-sm-12 d-none d-sm-block">
									<p className="section-subtitle" style={{ columnCount: 3 }}>
										{this.tailorData("body", 4246)}
									</p>
								</div>
								<div className="col-sm-12 d-block d-sm-none">
									<p className="section-subtitle">
										{this.tailorData("body", 4246)}
									</p>
								</div>
							</div>
						</section>
						<Element name="3DStorytelling" />
						<section className="section-white">
							<div className="container">
								<div className="row">
									<div className="col text-center">
										<h2 style={{ color: "#D1383F" }}>
											{this.tailorData("headline", 4247)}
										</h2>
									</div>
								</div>
								<div className="row">
									<div
										className="col-md-10 col-md-offset-1"
										style={{ alignSelf: "center" }}
									>
										<p>{this.tailorData("bullet_1", 4247)}</p>
									</div>
								</div>
								<div className="row">
									<div
										className="col-md-8 col-md-offset-2 text-center"
										style={{ alignSelf: "center" }}
									>
										<img
											alt="map"
											src={require("../images/map.svg")}
											style={{ width: "100%", paddingBottom: 15 }}
										/>
									</div>
								</div>
								<div className="row">
									<div
										className="col-md-10 col-md-offset-1"
										style={{ alignSelf: "center" }}
									>
										<p>{this.tailorData("bullet_2", 4247)}</p>
									</div>
								</div>
								<div className="row">
									<div
										className="col-md-8 col-md-offset-2 text-center"
										style={{ alignSelf: "center" }}
									>
										<img
											alt="evolution diagram"
											src={require("../images/evolutionDiagram.svg")}
											style={{ width: "100%", paddingBottom: 15 }}
										/>
									</div>
								</div>
								<div className="row">
									<div
										className="col-md-10 col-md-offset-1"
										style={{ alignSelf: "center" }}
									>
										<p>{this.tailorData("bullet_3", 4247)}</p>
									</div>
								</div>
								<div className="row">
									<div
										className="col-md-8 col-md-offset-2 text-center"
										style={{ alignSelf: "center" }}
									>
										<img
											alt="sentence diagram"
											src={require("../images/sentenceDiagram.svg")}
											style={{ width: "100%" }}
										/>
									</div>
								</div>
							</div>
						</section>
						<Element name="demo">
							<TailoringDemo tailorData={this.state.tailorData} />
						</Element>
						<Element name="about" />
						<section
							className="section-white"
							style={{ backgroundColor: "#C6C6C6" }}
						>
							<div className="container">
								<div className="row">
									<div className="col text-center">
										<h2>{this.tailorData("headline", 4249)}</h2>
									</div>
								</div>
								<div className="row">
									<div
										className="col d-sm-block d-md-none"
										style={{ alignSelf: "center" }}
									>
										<a
											href="https://wearetuzag.com"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												src={logoBlackWithBubble}
												alt="tuzag logo"
												style={{ width: "100%" }}
											/>
										</a>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<p>{this.tailorData("introduction", 4249)}</p>
									</div>
									<div
										className="col d-none d-md-block d-lg-block d-xl-block"
										style={{ alignSelf: "center" }}
									>
										<a
											href="https://wearetuzag.com"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												src={logoBlackWithBubble}
												alt="tuzag logo"
												style={{ width: "100%" }}
											/>
										</a>
									</div>
								</div>
								<div className="row">
									<div className="col text-center">
										<a
											href="https://wearetuzag.com"
											target="_blank"
											rel="noopener noreferrer"
										>
											<button
												className="btn btn-default"
												style={{
													borderColor: "black",
													paddingTop: 15,
													paddingBottom: 15,
													paddingLeft: 50,
													paddingRight: 50,
													width: "100%",
													maxWidth: 250,
													backgroundColor: "transparent"
												}}
												type="submit"
											>
												{this.tailorData("buttonLabel", 4249)}
											</button>
										</a>
									</div>
								</div>
							</div>
						</section>
						<Element name="contact" />
						<section className="section-white" id="contact">
							<div className="container">
								<div className="row">
									<div className="col-md-10 col-md-offset-1 text-center margin-bottom-30">
										<h2 className="section-title">
											{this.tailorData("headline", 4250)}
										</h2>
										{this.state.contactFormSubmitted ? null : (
											<p className="text-left">
												{this.tailorData("instructions", 4250)}
											</p>
										)}
									</div>
								</div>

								<div className="margin-20">
									{this.state.contactFormSubmitted ? (
										<div className="text-center">
											<p
												className="contact_success_box"
												style={{ color: "black", fontStyle: "normal" }}
											>
												{this.tailorData("postSendMessage", 4250)}
											</p>
										</div>
									) : (
										<form
											id="contact-form"
											className="contact"
											onSubmit={async evt => {
												evt.preventDefault();
												const formData = {
													name: document.getElementById("contact-names").value,
													email: document.getElementById("contact-email").value,
													recaptchaToken: this.state.recaptcha
												};

												let apiURL;

												if (process.env.GATSBY_REACT_APP_ENV === "staging") {
													apiURL =
														"https://api.staging.tuzagdesign.com/marketingSiteMailingList";
												} else {
													apiURL =
														process.env.NODE_ENV === "development"
															? "http://localhost:3000/marketingSiteMailingList"
															: "https://api.v2.tuzagdesign.com/marketingSiteMailingList";
												}

												fetch(apiURL, {
													method: "POST",
													credentials: "include",
													mode: "cors",
													headers: {
														"Content-Type": "application/json"
													},
													body: JSON.stringify(formData)
												});

												this.setState({ contactFormSubmitted: true });
											}}
										>
											<div className="row">
												<div className="col-md">
													<div className="row">
														<div
															className="col-xs-2 text-right"
															style={{
																paddingRight: 0,
																paddingTop: 18
															}}
														>
															<label
																htmlFor="contact-names"
																style={{ display: "block" }}
															>
																{this.tailorData("nameLabel", 4250, true)}
															</label>
														</div>
														<div className="col-xs-10">
															<input
																className="contact-input"
																placeholder={this.tailorData(
																	"namePlaceholder",
																	4250,
																	true
																)}
																type="text"
																id="contact-names"
															/>
														</div>
													</div>
												</div>

												<div className="col-md">
													<div
														className="d-md-none d-lg-none d-xl-none"
														style={{ marginTop: 20 }}
													/>

													<div className="row">
														<div
															className="col-xs-2 text-right"
															style={{
																paddingRight: 0,
																paddingTop: 18
															}}
														>
															<label
																htmlFor="contact-email"
																style={{ display: "block" }}
															>
																{this.tailorData("emailLabel", 4250)}
															</label>
														</div>
														<div className="col-xs-10">
															<input
																className="contact-input"
																placeholder={this.tailorData(
																	"emailPlaceholder",
																	4250,
																	true
																)}
																type="text"
																id="contact-email"
															/>
														</div>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col text-center">
													{this.state.recaptcha ? (
														<button
															className="btn btn-default"
															style={{
																borderColor: "#D1383F",
																paddingTop: 15,
																paddingBottom: 15,
																paddingLeft: 50,
																paddingRight: 50,
																width: "40%",
																minWidth: 200
															}}
															type="submit"
														>
															{this.tailorData("buttonLabel", 4250)}
														</button>
													) : (
														<ReCAPTCHA
															sitekey="6Lf1GKoUAAAAAMveW4W4SgcKJVPvHf4FXZUQBzM4"
															className="recaptcha"
															onChange={this.onChange}
														/>
													)}
												</div>
											</div>
										</form>
									)}
								</div>
							</div>
						</section>
						<Footer tailorData={this.state.tailorData} />
					</div>
				)}
				<Intercom appID="a48abriy" />
				<Modal
					isOpen={this.state.imageZoomModalOpen && this.state.windowWidth > 576}
					onRequestClose={() => {
						this.setState({ imageZoomModalOpen: false });
					}}
					style={{
						content: {
							top: "50%",
							left: "50%",
							right: "auto",
							bottom: "auto",
							marginRight: "-50%",
							transform: "translate(-50%, -50%)",
							width: "85%",
							minWidth: 300
							// height: "50%",
							// minHeight: 200
						}
					}}
				>
					<ContentZoom
						zoomPercent={350}
						largeImageUrl={"/static/img/svg.svg"}
						imageUrl={"/static/img/svg.svg"}
						contentHeight={800}
					/>
					<div className="text-center">
						<button
							className="btn btn-default"
							style={{
								borderColor: "black",
								paddingTop: 15,
								paddingBottom: 15,
								paddingLeft: 50,
								paddingRight: 50,
								width: "100%",
								maxWidth: 250,
								backgroundColor: "transparent"
							}}
							onClick={() => {
								this.setState({ imageZoomModalOpen: false });
							}}
						>
							Close
						</button>
					</div>
				</Modal>
			</div>
		);
	}
}
